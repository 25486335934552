export default function Intro() {
  return (
    <div className="relative isolate bg-gradient-to-r from-blue-500 via-blue-400 to-blue-300 py-12 sm:pt-12 sm:pb-2">
      {/* Decorative Top Element */}
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-16 -z-10 transform-gpu overflow-hidden blur-3xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-white to-blue-500 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        />
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <p className="text-base font-semibold leading-7 text-white">Expert Financial Services</p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Comprehensive Solutions for Your Business Needs
          </h1>
          <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-blue-100 lg:max-w-none lg:grid-cols-2">
            <div>
              <p>
                At SOF Financial Services, we are dedicated to providing top-tier financial services tailored to meet the unique
                needs of your business. Our experts specialize in accurate and timely monthly bookkeeping, ensuring your financial
                transactions are meticulously recorded.
              </p>
              <p className="mt-8">
                Our team of professionals offers strategic tax planning and preparation to minimize liabilities and ensure
                compliance. With our proactive approach, we keep a year-round eye on tax laws to provide you with the best possible
                outcomes.
              </p>
            </div>
            <div>
              <p>
                Our comprehensive financial statements give you clear insights into your business performance, helping you make
                informed decisions. Additionally, we offer expert IRS audit representation to protect your interests during audits.
              </p>
              <p className="mt-8">
                Leverage our user-friendly online portal for seamless record management, secure file storage, and real-time
                updates. Our platform ensures that you can access and share critical financial documents with ease, enhancing your
                overall financial management experience.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Call-to-Action Section */}
      <div className="mx-auto max-w-7xl px-6 sm:py-48 lg:py-16 lg:pb-32 lg:px-8">
        <div
          className="overflow-hidden rounded-3xl bg-gradient-to-br from-white to-gray-300 shadow-lg lg:grid lg:grid-cols-2 lg:gap-4"
        >
          <div className="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl">
                <span className="block">Ready to Take Control of Your Finances?</span>
                <span className="block text-xl sm:text-2xl">Start your journey with us today.</span>
              </h2>

              <p className="mt-4 text-lg leading-6 text-gray-800">
                Unlock comprehensive financial solutions and access our user-friendly portal for record management, file storage,
                and secure messaging. We utilize QuickBooks and TaxAct to ensure top-notch service.
              </p>
              <a
                href="/contact"
                className="mt-8 inline-flex items-center rounded-3xl border border-transparent bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 px-5 py-3 text-base font-medium shadow-sm  hover:text-white"
              >
                Book a Demo
              </a>
            </div>
          </div>
          <div className="aspect-h-4 aspect-w-3 -mt-6 md:aspect-h-16 md:aspect-w-9 shadow-lg">
            <img
              alt="App screenshot"
              src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/1040.PNG"
              className="w-full h-full transform rounded-md object-cover object-left-top sm:translate-x-12 lg:translate-y-24"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
