import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import './globals.css';

const navigation = [
  { name: 'Services', href: '/home#services', type: 'link' },
  { name: 'About', href: '/about', type: 'link' },
  { name: 'Schedule Consultation', href: '/contact', type: 'button' },
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <header className="absolute inset-x-0 top-0 z-50 mx-auto max-w-[120rem]">
      <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
        <div className={`flex lg:flex-1 w-full ${mobileMenuOpen ? 'justify-start' : 'justify-start'} lg:justify-start`}>
          <a href="/" className="flex items-center -m-1.5 p-1.5">
            <img
              alt="SOF Logo"
              src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/SVG+Vector+Files/Transparent+Logo.svg"
              className="h-40 w-auto lg:h-40 lg:block"
            />
          </a>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.slice(0, 2).map((item) => (
            <a key={item.name} href={item.href} className="text-base font-semibold leading-6 text-gray-900">
              {item.name}
            </a>
          ))}
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:gap-x-6">
          <button
            onClick={() => handleButtonClick('/contact')}
            className="inline-flex items-center justify-center rounded-3xl bg-blue-600 px-4 py-2 text-base font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Schedule Consultation
          </button>
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="flex items-center -m-1.5 p-1.5">
              <span className="sr-only">SOF</span>
              <img
                alt="SOF Logo"
                src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/SVG+Vector+Files/Transparent+Logo.svg"
                className="h-36 w-auto"
              />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6">
              <div className="space-y-2 py-6">
                {navigation.slice(0, 2).map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
                <button
                  onClick={() => handleButtonClick('/contact')}
                  className="-mx-1 mt-2 block w-full rounded-3xl bg-blue-600 px-4 py-2 text-base font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Schedule Consultation
                </button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
