export default function LearnMore() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:pt-0 lg:pb-24 lg:px-8">
        <div className="mt-10 flex items-center gap-x-6 justify-center">


            <a
              href="/contact"
              className="rounded-3xl bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Get started
            </a>
            <a href="/contact" className="text-sm font-semibold leading-6 text-blue-600 hover:text-blue-500">
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    )
  }
  